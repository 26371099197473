import React, { useEffect, useState } from 'react'
import {
  CButton,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
} from '@coreui/react/dist'
import { navigationPanel } from 'src/common/const'
import AuthService from 'src/services/AuthService'
import TokenService from 'src/services/TokenService'
import { navigationLevelChecker } from 'src/common/common'

function MainHeader() {
  const [navigationData, setNavigationData] = useState([])
  useEffect(() => {
    if (!localStorage.getItem('care-cloud-zoom-level'))
      localStorage.setItem('care-cloud-zoom-level', 80)

    if (!localStorage.getItem('navigation')) {
      localStorage.setItem('navigation', JSON.stringify(navigationPanel))
      navigationLevelChecker()
    }
    setNavigationData(JSON.parse(localStorage.getItem('navigation')))
    document.documentElement.style.zoom = `${Number(
      localStorage.getItem('care-cloud-zoom-level'),
    )}%`
    navigationLevelChecker()
  }, [])

  const [zoomLevel, setZoomLevel] = useState(100) // Initial zoom level

  const handleZoomIn = () => {
    if (!localStorage.getItem('care-cloud-zoom-level'))
      localStorage.setItem('care-cloud-zoom-level', 80)
    else {
      const newZoomLevel = Number(localStorage.getItem('care-cloud-zoom-level')) + 5
      localStorage.setItem('care-cloud-zoom-level', newZoomLevel)
      setZoomLevel(newZoomLevel)
      document.documentElement.style.zoom = `${newZoomLevel}%`
    }
  }

  const handleZoomOut = () => {
    if (!localStorage.getItem('care-cloud-zoom-level'))
      localStorage.setItem('care-cloud-zoom-level', 80)
    else {
      const newZoomLevel = Number(localStorage.getItem('care-cloud-zoom-level')) - 5
      localStorage.setItem('care-cloud-zoom-level', newZoomLevel)
      setZoomLevel(newZoomLevel)
      document.documentElement.style.zoom = `${newZoomLevel}%`
    }
    // const newZoomLevel = zoomLevel - 10
    // setZoomLevel(newZoomLevel)
    // document.documentElement.style.zoom = `${newZoomLevel}%`
  }

  const addToFav = (menu, item) => {
    const data = JSON.parse(localStorage.getItem('navigation'))

    const updatedMenu = menu.items.map((element) => {
      if (element.name == item.name) {
        element.isBookMarked = true
      }
      return element
    })
    const updatedData = data.map((element) => {
      if (element.name == menu.name) {
        element.items = updatedMenu
      }
      return element
    })
    localStorage.setItem('navigation', JSON.stringify(updatedData))
    window.location.reload(false)
  }

  const removeFromFav = (menu, item) => {
    const data = JSON.parse(localStorage.getItem('navigation'))

    const updatedMenu = menu.items.map((element) => {
      if (element.name == item.name) {
        element.isBookMarked = false
      }
      return element
    })
    const updatedData = data.map((element) => {
      if (element.name == menu.name) {
        element.items = updatedMenu
      }
      return element
    })
    localStorage.setItem('navigation', JSON.stringify(updatedData))
    window.location.reload(false)
  }

  const logOut = () => {
    AuthService.logout()
    window.location.reload(false)
    // change status
  }

  const resetSettings = () => {
    localStorage.removeItem('navigation')
    localStorage.removeItem('care-cloud-zoom-level')
    window.location.reload(false)

  } 

  const filterDropDownMenu = (items) => {
    const array = items.filter((item) => item.show)
    return array.length
  }

  return (
    <>
      <div>
        <CContainer fluid>
          <div style={{ fontSize: '0.5em', display: 'flex', justifyContent: 'end' }}>
            {navigationData.map((menu, key) =>
              menu.items.map((item, index) => (
                <CNavLink
                  href={item.href}
                  hidden={!item.isBookMarked || !item.show}
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    paddingLeft: '30px',
                    cursor: 'pointer',
                    color: '#000',
                  }}
                >
                  <span style={{ fontSize: '2em' }} className="material-symbols-outlined">
                    {item.icon}
                  </span>
                  <br />
                  <span> {item.name}</span>
                </CNavLink>
              )),
            )}
          </div>
        </CContainer>
      </div>
      <CNavbar expand="sm" style={{ backgroundColor: '#00C4BA' }}>
        <CNavbarBrand
          style={{
            marginLeft: '20px',
            marginRight: '50px',
            color: '#fff',
            fontWeight: 'bolder',
            fontSize: '2em',
            fontStyle: 'italic',
          }}
          href="/"
        >
          Care Cloud
        </CNavbarBrand>
        <CContainer fluid>
          <CNavbarNav style={{ fontSize: '0.9em' }}>
            {navigationData.map((menu, key) => (
            filterDropDownMenu(menu.items) != 0 && (<CDropdown
                variant="nav-item"
                popper={false}
                key={key}
                style={{
                  backgroundColor: '#fff',
                  margin: '10px',
                  borderRadius: '12px',
                  color: '#000',
                  fontWeight: 'bold',
                }}
              >
                <CDropdownToggle color="secondary" size="sm">
                  {menu.name}
                </CDropdownToggle>
                <CDropdownMenu style={{ fontSize: '1.1em', minWidth: '250px' }}>
                  {menu.items.map((item, index) => (
                    <CDropdownItem key={index} href={item.href} hidden={!item.show}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{item.name} </span>
                        {!item.isBookMarked ? (
                          <span
                            style={{ fontSize: '1em', paddingTop: '3px' }}
                            onClick={() => addToFav(menu, item)}
                            className="material-symbols-outlined"
                          >
                            star
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: '1em', paddingTop: '3px' }}
                            onClick={() => removeFromFav(menu, item)}
                            className="material-symbols-outlined"
                          >
                            do_not_disturb_on
                          </span>
                        )}
                      </div>
                    </CDropdownItem>
                  ))}
                  {/* <CDropdownDivider /> */}
                  {/* <CDropdownItem href="#">About</CDropdownItem> */}
                </CDropdownMenu>
              </CDropdown>)
            ))}
          </CNavbarNav>

          <div style={{ marginLeft: '200px' }}>
            <CButton
              onClick={handleZoomIn}
              variant="outline"
              color="light"
              style={{ color: '#fff', border: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <span style={{ fontSize: '2em' }} className="material-symbols-outlined">
                  zoom_in
                </span>
                Zoom In
              </div>
            </CButton>
            <CButton
              onClick={handleZoomOut}
              variant="outline"
              color="light"
              style={{ color: '#fff', border: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <span style={{ fontSize: '2em' }} className="material-symbols-outlined">
                  zoom_out
                </span>
                Zoom Out
              </div>
            </CButton>
          </div>
          <CDropdown
            dark
            variant="btn-group"
            direction="center"
            style={{
              textAlign: 'center',
              fontSize: '0.8em',
              padding: '10px',
              paddingLeft: '10px',
              cursor: 'pointer',
              textDecoration: 0,
            }}
          >
            <CButton color="light" size="sm">
              <div
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 10,
                  alignItems: 'center',
                  color: '#00C4BA',
                }}
              >
                <span
                  style={{
                    color: '#fff',
                    padding: 0,
                    fontSize: '1.1em',
                    color: '#00C4BA',
                    fontWeight: 'bold',
                  }}
                  className="material-symbols-outlined"
                >
                  person
                </span>
                <span
                  style={{
                    color: '#fff',
                    padding: 0,
                    fontSize: '0.9em',
                    color: '#00C4BA',
                    fontWeight: 'bold',
                  }}
                >
                  {TokenService.getUsername()}
                </span>
              </div>
            </CButton>
            <CDropdownToggle color="light" split />

            <CDropdownMenu>

              <CDropdownItem onClick={logOut}>Logout</CDropdownItem>
              <CDropdownItem onClick={resetSettings}>Reset Settings</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CContainer>
      </CNavbar>
    </>
  )
}

export default MainHeader
