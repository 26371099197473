import AuthService from "src/services/AuthService";
import TokenService from "src/services/TokenService"

export const tokenHeaderGenerator = () => {
    const header = {
        Authorization: `Bearer ${TokenService.getToken()}`
    }
    return header
}

export function isAnyAttributeEmpty(obj) {
    const keys = Object.keys(obj);
  
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = obj[key];
  
      if (value === null || value === undefined || value === '') {
        return true; // At least one attribute is empty
      }
    }
  
    return false; // No empty attributes found
  }

  export const navigationLevelChecker = async () => {
    const navigation = JSON.parse(localStorage.getItem('navigation'))
    const userLevel = TokenService.getUserLevel()

    navigation.forEach(mainHeader => {
        mainHeader.items.forEach(item => {
            if(item?.level.includes(Number(userLevel))) item.show = true
            else item.show = false
        })
    });

    localStorage.setItem('navigation', JSON.stringify(navigation))
  }